import React, {useMemo} from 'react'
import { Template } from '../../../components/template'
import { useQueryParam, StringParam } from 'use-query-params'
import { MdClose } from 'react-icons/md'
import {
  Title,
  CustomButton,
  ClientName,
  ClientsButtonsContainer,
  QrCodeContainer,
  BottomImagesContainer,
  CustomIframe,
  ModalCloseButton,
  BottomIllustrationContainer,
  WhatsappImg,
  ClientsHomeContentWrapper,
  WelcomeContainer,
  NegativeMarginContainer
} from '../styles'
import { QRCodeSVG } from 'qrcode.react'
import fordiLogo from '../../../assets/images/dealers/fordi-logo.png'
import Modal from 'react-modal'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import FordLogoDealers from '../components/icons/ford-logo-dealers'
import BottomPageIllustration from '../components/icons/bottom-page-illustration'

const DealersHome = ({ pageContext, location }) => {
  console.log({
    pageContext,
    location
  })
  const breakpoints = useBreakpoint()

  const [clientName] = useQueryParam('clientName', StringParam)
  const [vin] = useQueryParam('vin', StringParam)
  const [dealerCode] = useQueryParam('dealerCode', StringParam)

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

  const {
    website: {
      meta_title,
      meta_description,
      client_main_title_text,
      welcome_video_button,
      go_to_experience_button,
      qr_code_link,
      tell_us_how_was_your_experience_text,
      video_link,
      guide_link,
      show_video
    }
  } = pageContext

  const goToExperienceLink = useMemo(() => {
    let experienceParams = `?vin=${vin}&utm_source=dealers&utm_medium=delears_page&utm_campaign=dealers`

    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)

      params.delete('vin')
      params.delete('clientName')
      params.delete('dealerCode')

      const stringParams = params.toString()

      if (stringParams) {
        experienceParams += `&${stringParams}`
      }
    }

    return `${guide_link}/${experienceParams}`
  },[guide_link, vin])


  return (
    <>
      <ClientsHomeContentWrapper>
        <Template title={meta_title} description={meta_description} language={'PT-br'} has_cookie_consent={false}>
          <NegativeMarginContainer>
            <ClientName>{clientName}</ClientName>
            <WelcomeContainer>
              <Title>{client_main_title_text}</Title>
              <FordLogoDealers />
            </WelcomeContainer>
            <ClientsButtonsContainer>
              <QrCodeContainer>
                <QRCodeSVG value={goToExperienceLink} />
              </QrCodeContainer>
              <div>
                {show_video && <CustomButton onClick={onModalOpen}>{welcome_video_button}</CustomButton>}
                <a href={goToExperienceLink} target="_blank" rel="noopener noreferrer">
                  <CustomButton>{go_to_experience_button}</CustomButton>
                </a>
              </div>
            </ClientsButtonsContainer>
            <BottomImagesContainer>
              <p>{tell_us_how_was_your_experience_text}</p>
              <a href={qr_code_link} target="_blank" rel="noopener noreferrer">
                <WhatsappImg alt="whatsapp logo" src={fordiLogo} />
              </a>
              <QRCodeSVG value={qr_code_link} size={breakpoints.sm ? 75 : 102} />
            </BottomImagesContainer>
          </NegativeMarginContainer>
        </Template>
        <BottomIllustrationContainer>
          <BottomPageIllustration />
        </BottomIllustrationContainer>
      </ClientsHomeContentWrapper>

      <Modal
        isOpen={isModalOpen}
        onAfterOpen={() => {}}
        onRequestClose={onModalClose}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 0,
            outline: 'none',
            minWidth: '90vw',
            maxWidth: '100vw',
            minHeight: '75vh'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <ModalCloseButton onClick={onModalClose}>
          <MdClose size={20} />
        </ModalCloseButton>
        <CustomIframe
          src={`${video_link}`}
          title="Ford Boas Vindas"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
    </>
  )
}

export default DealersHome
